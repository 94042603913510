@charset "UTF-8";
/*!--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
#outdated {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1500;
  background-color: #f25648;
  color: #ffffff; }
  #outdated h6 {
    font-size: 25px;
    line-height: 25px;
    margin: 30px 0 10px; }
  #outdated p {
    font-size: 12px;
    line-height: 12px;
    margin: 0; }
  #outdated #btnUpdateBrowser {
    display: block;
    position: relative;
    padding: 10px 20px;
    margin: 30px auto 0;
    width: 230px;
    /*need for IE*/
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #ffffff;
    cursor: pointer; }
    #outdated #btnUpdateBrowser:hover {
      color: #f25648;
      background-color: #ffffff; }
  #outdated .last {
    position: absolute;
    top: 10px;
    right: 25px;
    width: 20px;
    height: 20px; }
    #outdated .last[dir='rtl'] {
      right: auto !important;
      left: 25px !important; }
  #outdated #btnCloseUpdateBrowser {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    font-size: 36px;
    line-height: 36px; }
  * html #outdated {
    position: absolute; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

select.bs-select-hidden,
.bootstrap-select > select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/
  vertical-align: middle; }
  .bootstrap-select > .dropdown-toggle {
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: right;
    white-space: nowrap; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
      color: #999; }
    .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-primary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-secondary:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-success:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-danger:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-info:active, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder.btn-dark:active {
      color: rgba(255, 255, 255, 0.5); }
  .bootstrap-select > select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none; }
    .bootstrap-select > select.mobile-device {
      top: 0;
      left: 0;
      display: block !important;
      width: 100% !important;
      z-index: 2; }
  .has-error .bootstrap-select .dropdown-toggle,
  .error .bootstrap-select .dropdown-toggle,
  .bootstrap-select.is-invalid .dropdown-toggle,
  .was-validated .bootstrap-select .selectpicker:invalid + .dropdown-toggle {
    border-color: #b94a48; }
  .bootstrap-select.is-valid .dropdown-toggle,
  .was-validated .bootstrap-select .selectpicker:valid + .dropdown-toggle {
    border-color: #28a745; }
  .bootstrap-select.fit-width {
    width: auto !important; }
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 220px; }
  .bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
  .bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }
  :not(.input-group) > .bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%; }
  .bootstrap-select.form-control.input-group-btn {
    z-index: auto; }
    .bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
      border-radius: 0; }

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.dropdown-menu-right, .bootstrap-select[class*="col-"].dropdown-menu-right,
.row .bootstrap-select[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select,
.form-horizontal .bootstrap-select,
.form-group .bootstrap-select {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.form-control,
.form-group-sm .bootstrap-select.form-control {
  padding: 0; }
  .form-group-lg .bootstrap-select.form-control .dropdown-toggle,
  .form-group-sm .bootstrap-select.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle, .input-group-sm > .bootstrap-select.form-control .dropdown-toggle, .input-group-sm > .input-group-prepend > .bootstrap-select.input-group-text .dropdown-toggle, .input-group-sm > .input-group-append > .bootstrap-select.input-group-text .dropdown-toggle, .input-group-sm > .input-group-prepend > .bootstrap-select.btn .dropdown-toggle, .input-group-sm > .input-group-append > .bootstrap-select.btn .dropdown-toggle,
.bootstrap-select.form-control-lg .dropdown-toggle,
.input-group-lg > .bootstrap-select.form-control .dropdown-toggle,
.input-group-lg > .input-group-prepend > .bootstrap-select.input-group-text .dropdown-toggle,
.input-group-lg > .input-group-append > .bootstrap-select.input-group-text .dropdown-toggle,
.input-group-lg > .input-group-prepend > .bootstrap-select.btn .dropdown-toggle,
.input-group-lg > .input-group-append > .bootstrap-select.btn .dropdown-toggle {
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.bootstrap-select.form-control-sm .dropdown-toggle, .input-group-sm > .bootstrap-select.form-control .dropdown-toggle, .input-group-sm > .input-group-prepend > .bootstrap-select.input-group-text .dropdown-toggle, .input-group-sm > .input-group-append > .bootstrap-select.input-group-text .dropdown-toggle, .input-group-sm > .input-group-prepend > .bootstrap-select.btn .dropdown-toggle, .input-group-sm > .input-group-append > .bootstrap-select.btn .dropdown-toggle {
  padding: 0.25rem 0.5rem; }


.bootstrap-select.form-control-lg .dropdown-toggle,
.input-group-lg > .bootstrap-select.form-control .dropdown-toggle,
.input-group-lg > .input-group-prepend > .bootstrap-select.input-group-text .dropdown-toggle,
.input-group-lg > .input-group-append > .bootstrap-select.input-group-text .dropdown-toggle,
.input-group-lg > .input-group-prepend > .bootstrap-select.btn .dropdown-toggle,
.input-group-lg > .input-group-append > .bootstrap-select.btn .dropdown-toggle {
  padding: 0.5rem 1rem; }

.form-inline .bootstrap-select .form-control {
  width: 100%; }

.bootstrap-select.disabled,
.bootstrap-select > .disabled {
  cursor: not-allowed; }
  .bootstrap-select.disabled:focus,
  .bootstrap-select > .disabled:focus {
    outline: none !important; }

.bootstrap-select.bs-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 0 !important;
  padding: 0 !important; }
  .bootstrap-select.bs-container .dropdown-menu {
    z-index: 1060; }

.bootstrap-select .dropdown-toggle:before {
  content: '';
  display: inline-block; }

.bootstrap-select .dropdown-toggle .filter-option {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: inherit;
  padding-right: inherit;
  padding-bottom: inherit;
  padding-left: inherit;
  height: 100%;
  width: 100%;
  text-align: left; }

.bootstrap-select .dropdown-toggle .filter-option-inner {
  padding-right: inherit; }

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden; }

.bootstrap-select .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.input-group .bootstrap-select.form-control .dropdown-toggle {
  border-radius: inherit; }

.bootstrap-select[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bootstrap-select .dropdown-menu > .inner:focus {
    outline: none !important; }
  .bootstrap-select .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none; }
  .bootstrap-select .dropdown-menu li {
    position: relative; }
    .bootstrap-select .dropdown-menu li.active small {
      color: rgba(255, 255, 255, 0.5) !important; }
    .bootstrap-select .dropdown-menu li.disabled a {
      cursor: not-allowed; }
    .bootstrap-select .dropdown-menu li a {
      cursor: pointer;
      user-select: none; }
      .bootstrap-select .dropdown-menu li a.opt {
        position: relative;
        padding-left: 2.25em; }
      .bootstrap-select .dropdown-menu li a span.check-mark {
        display: none; }
      .bootstrap-select .dropdown-menu li a span.text {
        display: inline-block; }
    .bootstrap-select .dropdown-menu li small {
      padding-left: 0.5em; }
  .bootstrap-select .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: whitesmoke;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.bootstrap-select .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  position: static;
  display: inline;
  padding: 0; }

.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner,
.bootstrap-select.fit-width .dropdown-toggle .filter-option-inner-inner {
  display: inline; }

.bootstrap-select.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  top: 5px; }

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select .bs-ok-default:after {
  content: '';
  display: block;
  width: 0.5em;
  height: 1em;
  border-style: solid;
  border-width: 0 0.26em 0.26em 0;
  transform: rotate(45deg); }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle .filter-option:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:before {
  bottom: auto;
  top: -4px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle .filter-option:after {
  bottom: auto;
  top: -4px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle .filter-option:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:before, .bootstrap-select.show-menu-arrow.open > .dropdown-toggle .filter-option:after,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:before,
.bootstrap-select.show-menu-arrow.show > .dropdown-toggle .filter-option:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-actionsbox .btn-group button {
    width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .bs-donebutton .btn-group button {
    width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/* === Bootstrap Global === */
/* === Header / Navigation === */
/* === Colors === */
/* === Bricks === */
/* === Stage === */
.bootstrap-select {
  position: relative; }
  .bootstrap-select .btn-default.dropdown-toggle {
    outline: 0 !important;
    border-radius: 0;
    border: none; }
  .bootstrap-select .dropdown-menu {
    border-radius: 0; }
  .bootstrap-select > select {
    display: none !important; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

h4, h4 {
  font-size: 1.25rem;
  color: #482b1e;
  margin-bottom: 15px; }

/* mixin for multiline */
/*-------------------------------------------------------------------------*/
/*	Global */
/*-------------------------------------------------------------------------*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #ca0015; }
  a:hover {
    color: #b00013; }

.hidden {
  display: none !important; }

small small, small .small, .small small, .small .small {
  font-size: 100%; }

.sticky {
  position: fixed;
  max-width: 1920px;
  top: 0;
  width: 100%;
  z-index: 200; }

.has-error .form-control-feedback {
  color: #dc3545; }

.has-error .form-control {
  border-color: #dc3545; }

.has-warning .form-control-feedback {
  color: #e30018; }

.has-warning .form-control {
  color: #e30018; }

.has-warning input[type="checkbox"] ~ label {
  color: #e30018 !important; }

.has-success .form-control-feedback {
  color: #28a745; }

.has-success .form-control {
  border-color: #28a745; }

.anchor-inner {
  position: absolute;
  top: -75px; }
  .anchor-inner__container {
    position: relative; }
  .anchor-inner--reduced {
    top: -20px; }

body.online .d-offline {
  display: none; }

body.offline .d-online {
  display: none; }

/******************
 * COOKIE POLICY
 ******************/
/* Change the white to any color ;) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset; }

/*----------------------*/
/*	reCAPTCHA BADGE     */
/*----------------------*/
.grecaptcha-badge {
  visibility: hidden; }
  .grecaptcha-badge--visible {
    visibility: visible; }

@media (max-width: 991.98px) {
  form .bootstrap-select .btn,
  form .bootstrap-select .dropdown-menu {
    font-size: 13px; } }

.form-control[type="checkbox"] {
  width: auto;
  height: auto; }

#siteWrapper {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden; }

span.icon {
  width: 15px;
  height: 15px;
  display: block; }
  span.icon svg {
    max-width: 100%;
    max-height: 100%; }

.btn.btn-primary {
  background-color: #e30018;
  border: none;
  position: relative; }
  .btn.btn-primary:hover, .btn.btn-primary a:hover {
    border: none;
    color: #482b1e;
    text-decoration: none; }
    .btn.btn-primary:hover:after, .btn.btn-primary a:hover:after {
      opacity: 1; }
  .btn.btn-primary:focus, .btn.btn-primary:active {
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none; }

.btn.btn-secondary {
  background-color: #482b1e;
  border: none; }
  .btn.btn-secondary:hover {
    color: #e30018; }

.btn.btn-ghost {
  border: 1px solid black;
  color: #482b1e; }
  .btn.btn-ghost:hover {
    color: #e30018; }

.btn.btn-icon-white {
  background: #FFFFFF;
  border: 1px solid #F2F1ED;
  color: #e30018;
  padding: 8px 10px; }
  .btn.btn-icon-white span {
    display: block;
    float: left;
    line-height: 28px;
    font-weight: bold; }
    .btn.btn-icon-white span.icon {
      width: 25px;
      height: 25px;
      margin-right: 10px; }
    .btn.btn-icon-white span svg {
      fill: #e30018; }
  .btn.btn-icon-white:hover {
    border: 1px solid #e30018; }

.location-address__email {
  padding-top: 15px; }
  .location-address__email a {
    position: relative;
    padding-left: 25px;
    color: #e30018;
    transition: all 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .location-address__email a {
        transition: none; } }
    .location-address__email a svg {
      fill: #e30018;
      transition: all 300ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        .location-address__email a svg {
          transition: none; } }
    .location-address__email a:hover {
      transition: all 300ms;
      color: #482b1e;
      text-decoration: none; }
      @media screen and (prefers-reduced-motion: reduce) {
        .location-address__email a:hover {
          transition: none; } }
      .location-address__email a:hover svg {
        transition: all 300ms;
        fill: #482b1e; }
        @media screen and (prefers-reduced-motion: reduce) {
          .location-address__email a:hover svg {
            transition: none; } }
  .location-address__email span.icon {
    position: absolute;
    left: 0;
    top: 0; }

body[data-layout="messe"] .d-none-layout-fair, body[data-layout="fair"] .d-none-layout-fair {
  display: none !important; }

body[data-layout="messe"] span.icon svg, body[data-layout="fair"] span.icon svg {
  width: 100%;
  height: 100%; }

.brick {
  padding: 75px 0; }
  .brick .brick {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 0; }
    .brick .brick:first-child {
      padding-top: 0; }
    .brick .brick:last-child {
      padding-bottom: 0; }
  .brick__header {
    margin-bottom: 30px; }
    .brick__header--reduced {
      margin-bottom: 15px; }
    .brick__header--headline, .brick__header--sub-headline {
      color: #482b1e; }
    .brick__header--info-text, .brick__header--info-text > * {
      width: 100%; }
  .brick__footer {
    margin-top: 30px;
    flex-direction: column; }
    @media (min-width: 768px) {
      .brick__footer {
        flex-direction: row; } }
    .brick__footer .brick__footer {
      margin-top: 15px; }
    .brick__footer .cta-button-item__sub-text {
      margin: 5px 0 0 0; }
    .brick__footer.justify-content-start .cta-button-item {
      margin: 5px 10px 0 0;
      text-align: left; }
    .brick__footer.justify-content-center .cta-button-item {
      margin: 5px 10px 0;
      text-align: center; }
    .brick__footer.justify-content-end .cta-button-item {
      margin: 5px 0 0 10px;
      text-align: right; }

.area-section--grey-background {
  background-color: #f5f5ed; }

.less-top-padding {
  padding-top: 0; }

.less-bottom-padding {
  padding-bottom: 0; }

.area-wysiwyg p:last-child {
  margin-bottom: 0; }

.area-wysiwyg__content h1, .area-wysiwyg__content h2, .area-wysiwyg__content h3 {
  color: #e30018; }

.area-wysiwyg__content p {
  color: #482b1e; }

.area-wysiwyg__content ul {
  list-style-type: none;
  padding-left: 0; }

.area-wysiwyg__content ul > li {
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 26px;
  margin: 0;
  color: #482b1e;
  background-image: url("/static/icomoon/SVG/rue_red.svg");
  background-repeat: no-repeat;
  padding-left: 1.5rem;
  background-size: 1rem;
  background-position: 0 6px; }
  @media (min-width: 576px) {
    .area-wysiwyg__content ul > li {
      font-size: 15px; } }
  @media (min-width: 768px) {
    .area-wysiwyg__content ul > li {
      font-size: 15px; } }
  @media (min-width: 992px) {
    .area-wysiwyg__content ul > li {
      font-size: 15px; } }

.area-video__video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  padding-top: 0; }
  .area-video__video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.area-cta {
  background: #eee; }

.area-teaser {
  padding: 40px 0; }
  .area-teaser .h2 {
    color: #e30018;
    font-size: 28px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 38px;
    margin: 0; }
    @media (min-width: 576px) {
      .area-teaser .h2 {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .area-teaser .h2 {
        font-size: 28px; } }
    @media (min-width: 992px) {
      .area-teaser .h2 {
        font-size: 28px; } }
  .area-teaser .h1 {
    color: #e30018;
    font-size: 40px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 55px;
    margin: 0; }
    @media (min-width: 576px) {
      .area-teaser .h1 {
        font-size: 40px; } }
    @media (min-width: 768px) {
      .area-teaser .h1 {
        font-size: 40px; } }
    @media (min-width: 992px) {
      .area-teaser .h1 {
        font-size: 40px; } }
  .area-teaser .teaser-items .pimcore_tag_block {
    display: flex;
    flex-wrap: wrap; }
  .area-teaser .teaser-items--twoColumn .pimcore_block_entry {
    width: 50%;
    clear: none;
    padding: 0 15px; }
  .area-teaser .teaser-items--threeColumn .pimcore_block_entry {
    width: 33.3333333333%;
    clear: none;
    padding: 0 15px; }
  .area-teaser .teaser-items--panorama .pimcore_block_entry {
    width: 100%;
    clear: none;
    padding: 0 15px; }
  .area-teaser .panorama__arrows {
    opacity: 0.3;
    cursor: pointer;
    outline: none;
    width: 30px; }
    .area-teaser .panorama__arrows .carousel-control-prev {
      left: -150px; }
      .area-teaser .panorama__arrows .carousel-control-prev .icon {
        width: 25px;
        height: 25px; }
    .area-teaser .panorama__arrows .carousel-control-next {
      right: -150px; }
      .area-teaser .panorama__arrows .carousel-control-next .icon {
        width: 25px;
        height: 25px; }
  .area-teaser__items .teaser-item {
    padding-bottom: 15px; }
    @media (min-width: 1200px) {
      .area-teaser__items .teaser-item {
        padding-bottom: 30px; } }
  .area-teaser__items .teaser-object {
    display: flex;
    flex-direction: column; }
    .area-teaser__items .teaser-object--default {
      background-color: #FFFFFF;
      border: solid 0.8px #e1ded6; }
      .area-teaser__items .teaser-object--default .teaser-object__label {
        display: block;
        top: 0.25rem; }
    .area-teaser__items .teaser-object--default.teaser-object--has-logo .teaser-object__label {
      top: 5.3125rem; }
    .area-teaser__items .teaser-object--panorama-wrapper {
      background-color: #FFFFFF;
      padding-top: 15px; }
    .area-teaser__items .teaser-object--panorama-justify {
      border: solid 1.1px #e1ded6; }
      @media (min-width: 320px) and (max-width: 1366px) {
        .area-teaser__items .teaser-object--panorama-justify {
          overflow: hidden; } }
    .area-teaser__items .teaser-object__image {
      order: 2;
      height: 100%;
      width: 100%; }
      .area-teaser__items .teaser-object__image img {
        object-fit: cover;
        height: 100%;
        width: 100%; }
        @media (min-width: 320px) and (max-width: 768px) {
          .area-teaser__items .teaser-object__image img {
            width: 100%; } }
    .area-teaser__items .teaser-object__label {
      display: none;
      position: absolute;
      top: 0.25rem;
      color: #e30018;
      font-size: 15px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 18px;
      margin: 0;
      font-weight: bold;
      color: #FFFFFF;
      padding: 0.3125rem 0.625rem 0.375rem;
      background: rgba(227, 0, 24, 0.9);
      min-width: 8.125rem;
      text-align: center; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__label {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__label {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__label {
          font-size: 15px; } }
    .area-teaser__items .teaser-object__sub-headline {
      color: #e30018;
      font-size: 15px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 18px;
      margin: 0;
      color: #482b1e;
      padding-bottom: 10px; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__sub-headline {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__sub-headline {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__sub-headline {
          font-size: 15px; } }
    .area-teaser__items .teaser-object__headline {
      order: 3;
      color: #e30018;
      font-size: 20px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 27px;
      margin: 0;
      padding-bottom: 10px; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__headline {
          font-size: 20px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__headline {
          font-size: 20px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__headline {
          font-size: 20px; } }
    .area-teaser__items .teaser-object__text {
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      order: 4;
      padding: 0 0 16px 0; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__text {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__text {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__text {
          font-size: 15px; } }
    .area-teaser__items .teaser-object__link {
      order: 5;
      padding: 16px 0 0;
      color: #482b1e;
      font-size: 15px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      color: #e30018; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__link {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__link {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__link {
          font-size: 15px; } }
      .area-teaser__items .teaser-object__link .icon-rue_m {
        fill: #e30018;
        display: inline-block;
        margin-right: 8px; }
      .area-teaser__items .teaser-object__link:hover {
        text-decoration: none;
        color: #482b1e; }
        .area-teaser__items .teaser-object__link:hover .icon-rue_m {
          fill: #482b1e; }
    .area-teaser__items .teaser-object__logo {
      height: 80px;
      width: auto;
      margin-bottom: 1px; }
      .area-teaser__items .teaser-object__logo-wrapper {
        text-align: center; }
    .area-teaser__items .teaser-object__info-wrapper {
      order: 3;
      padding: 20px 16px 16px; }
    .area-teaser__items .teaser-object__label-panorama {
      position: absolute;
      z-index: 500;
      top: 25px;
      left: -140px;
      text-align: center;
      min-width: 262px;
      background-color: #482b1e;
      padding: 8px 0;
      color: #e30018;
      font-size: 20px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 27px;
      margin: 0;
      color: #f5f5ed; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__label-panorama {
          font-size: 20px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__label-panorama {
          font-size: 20px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__label-panorama {
          font-size: 20px; } }
    .area-teaser__items .teaser-object__text-panorama {
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      margin-top: 10px;
      padding-left: 15px;
      margin-bottom: 20px; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__text-panorama {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__text-panorama {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__text-panorama {
          font-size: 15px; } }
    .area-teaser__items .teaser-object__headline-panorama {
      color: #e30018;
      font-size: 20px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 27px;
      margin: 0;
      padding-left: 15px; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__headline-panorama {
          font-size: 20px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__headline-panorama {
          font-size: 20px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__headline-panorama {
          font-size: 20px; } }
    .area-teaser__items .teaser-object__link-panorama {
      padding: 0 15px;
      color: #482b1e;
      font-size: 15px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      color: #e30018; }
      @media (min-width: 576px) {
        .area-teaser__items .teaser-object__link-panorama {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-teaser__items .teaser-object__link-panorama {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-teaser__items .teaser-object__link-panorama {
          font-size: 15px; } }
      .area-teaser__items .teaser-object__link-panorama .icon-rue_m {
        fill: #e30018;
        display: inline-block;
        margin-right: 8px; }
      .area-teaser__items .teaser-object__link-panorama:hover {
        text-decoration: none;
        color: #482b1e; }
        .area-teaser__items .teaser-object__link-panorama:hover .icon-rue_m {
          fill: #482b1e; }
    .area-teaser__items .teaser-object__logo-panorama-wrapper {
      text-align: center; }
      .area-teaser__items .teaser-object__logo-panorama-wrapper img {
        height: 127px;
        width: auto;
        margin: 10px auto; }
  @media (min-width: 992px) {
    .area-teaser__items--panorama .teaser-item.left .teaser-object__label {
      right: 0;
      left: auto; } }
  .area-teaser__items--panorama .teaser-object__sub-headline {
    padding-left: 15px; }
  .area-teaser__items--panorama .teaser-object__label {
    display: block;
    top: 2.75rem; }
    @media (min-width: 992px) {
      .area-teaser__items--panorama .teaser-object__label {
        top: 0.25rem; } }
  .area-teaser__items--slider {
    position: relative;
    padding: 0 0;
    padding-bottom: 30px; }
    .area-teaser__items--slider .teaser-items__row {
      margin: 0;
      outline: none; }
    @media (min-width: 768px) {
      .area-teaser__items--slider .teaser-item {
        padding-bottom: 0; } }
    .area-teaser__items--slider .slick-arrow {
      opacity: 0.3;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      outline: none;
      width: 30px;
      height: 30px; }
      .area-teaser__items--slider .slick-arrow img {
        width: 30px;
        height: 30px; }
      .area-teaser__items--slider .slick-arrow.prev {
        left: -90px; }
      .area-teaser__items--slider .slick-arrow.next {
        right: -90px; }
  .area-teaser__items--masonry {
    margin-left: -15px;
    margin-right: -15px; }
    .area-teaser__items--masonry .teaser-item {
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0;
      margin-bottom: 15px;
      float: left; }
      .area-teaser__items--masonry .teaser-item--twoColumn {
        width: 50%; }
      .area-teaser__items--masonry .teaser-item--threeColumn {
        width: 33.333333%; }
  .area-teaser__items .slick-slide {
    height: auto; }
  .area-teaser__items .slick-dots {
    opacity: 0.3; }
    .area-teaser__items .slick-dots li {
      margin: 0 0 3px;
      height: auto;
      width: auto; }
      .area-teaser__items .slick-dots li img:nth-child(1) {
        display: block;
        height: 6px;
        margin: 0 4px; }
      .area-teaser__items .slick-dots li img:nth-child(2) {
        display: none;
        height: 12px; }
      .area-teaser__items .slick-dots li.slick-active {
        margin-bottom: 0; }
        .area-teaser__items .slick-dots li.slick-active img:nth-child(1) {
          display: none;
          height: 6px; }
        .area-teaser__items .slick-dots li.slick-active img:nth-child(2) {
          display: block;
          height: 12px;
          margin: 0 2px; }
  .area-teaser .left .carousel-wrapper {
    display: flex;
    flex-direction: row; }
    .area-teaser .left .carousel-wrapper__inner {
      order: 2; }
  .area-teaser .right .carousel-wrapper {
    display: flex;
    flex-direction: row; }
    .area-teaser .right .carousel-wrapper__inner {
      order: 1; }
  .area-teaser .right .right {
    order: 2; }
  .area-teaser .right .teaser-object--panorama .teaser-object--panorama-justify .col-lg-8 {
    order: 2;
    background: #FFFFFF; }
  .area-teaser .right .teaser-object--panorama .teaser-object--panorama-justify .teaser-object--panorama-wrapper {
    order: 1; }
  .area-teaser .right .teaser-object--panorama .teaser-object__label-panorama {
    left: 810px; }
    @media (min-width: 320px) and (max-width: 1200px) {
      .area-teaser .right .teaser-object--panorama .teaser-object__label-panorama {
        left: 630px; } }
  .area-teaser .right .panorama__arrows .carousel-control-prev {
    left: -150px; }
  .area-teaser .right .panorama__arrows .carousel-control-next {
    right: -150px; }
  .area-teaser .carousel-inner {
    overflow: unset; }
  .area-teaser .carousel.carousel-fade .carousel-inner.carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity; }
  .area-teaser .carousel-fade .carousel-inner.carousel-item .active {
    opacity: 1; }
  .area-teaser .carousel-fade .carousel-inner.carousel-item .active.left,
  .area-teaser .carousel-fade .carousel-inner.carousel-item .active.right {
    left: 0;
    opacity: 0;
    z-index: 1; }
  .area-teaser .carousel-fade .carousel-inner.carousel-item .next.left,
  .area-teaser .carousel-fade .carousel-inner.carousel-item .prev.right {
    opacity: 1; }
  .area-teaser .carousel-fade .carousel-control {
    z-index: 2; }
  @media screen and (min-width: 768px) and (max-width: 1366px) {
    .area-teaser .carousel-wrapper {
      justify-content: center; }
    .area-teaser .carousel-wrapper__inner {
      display: none; } }
  @media screen and (min-width: 320px) and (max-width: 992px) {
    .area-teaser .right .teaser-object--panorama .teaser-object__label-panorama {
      position: inherit; }
    .area-teaser .teaser-object__label-panorama {
      position: inherit; }
    .area-teaser .left .teaser-object--panorama .teaser-object--panorama-justify .col-lg-8 {
      order: 2; } }

.select-wrapper {
  border-radius: 0.25rem; }
  .select-wrapper select {
    border: none;
    box-shadow: none;
    background-image: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .select-wrapper .form-select-label {
    display: none; }

.area-contact-form .form-control {
  color: #b7b6b0; }

.area-contact-form .form-group {
  position: relative;
  padding-top: 15px;
  /*
    input, textarea {
      border: 1px solid $color-pinkish-grey;
      box-shadow: none;
      &::placeholder {
        opacity: 0;
      }
      &:focus, &:not(:placeholder-shown) {
        & + label {
          top: 1px;
        }
      }
    }
*/ }
  .area-contact-form .form-group .form-check {
    padding-top: 0; }
    .area-contact-form .form-group .form-check .form-check-input {
      width: 15px;
      height: 15px; }
    .area-contact-form .form-group .form-check label {
      position: relative;
      top: 0;
      left: 0;
      color: black;
      font-size: 0.75rem; }
  .area-contact-form .form-group a {
    color: #e30018;
    font-size: 0.75rem; }
  .area-contact-form .form-group .select-wrapper {
    border: 1px solid #b7b6b0;
    border-radius: 0.25rem; }
    .area-contact-form .form-group .select-wrapper select {
      border: none;
      box-shadow: none;
      background: transparent;
      background-image: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
    .area-contact-form .form-group .select-wrapper label {
      top: 1px; }
    .area-contact-form .form-group .select-wrapper .form-select-label {
      display: none; }
    .area-contact-form .form-group .select-wrapper #contact_salutation {
      height: 2.25rem; }
  .area-contact-form .form-group label {
    position: absolute;
    top: 1px;
    left: 23px;
    background: #fff;
    padding: 0 5px;
    transition: top .2s;
    color: #b7b6b0; }
  .area-contact-form .form-group input:not([type=checkbox]), .area-contact-form .form-group textarea {
    border: 1px solid #b7b6b0;
    box-shadow: none;
    /**
      * When the element is focused, remove the label transform.
      * Also, do this when the placeholder is _not_ shown, i.e. when
      * there's something in the input at all.
      */ }
    .area-contact-form .form-group input:not([type=checkbox])::-webkit-input-placeholder, .area-contact-form .form-group textarea::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: transparent; }
    .area-contact-form .form-group input:not([type=checkbox])::-moz-placeholder, .area-contact-form .form-group textarea::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent; }
    .area-contact-form .form-group input:not([type=checkbox]):-ms-input-placeholder, .area-contact-form .form-group textarea:-ms-input-placeholder {
      /* IE 10+ */
      color: transparent; }
    .area-contact-form .form-group input:not([type=checkbox]):-moz-placeholder, .area-contact-form .form-group textarea:-moz-placeholder {
      /* Firefox 18- */
      color: transparent; }
    .area-contact-form .form-group input:not([type=checkbox]):focus + label, .area-contact-form .form-group textarea:focus + label {
      top: 1px !important; }
    .area-contact-form .form-group input:not([type=checkbox]):not(:placeholder-shown) + label, .area-contact-form .form-group textarea:not(:placeholder-shown) + label {
      top: 1px !important; }
    .area-contact-form .form-group input:not([type=checkbox]):not(:-ms-input-placeholder) + label, .area-contact-form .form-group textarea:not(:-ms-input-placeholder) + label {
      top: 1px !important; }
    .area-contact-form .form-group input:not([type=checkbox]):placeholder-shown + label, .area-contact-form .form-group textarea:placeholder-shown + label {
      top: 22px; }
    .area-contact-form .form-group input:not([type=checkbox]):-ms-input-placeholder + label, .area-contact-form .form-group textarea:-ms-input-placeholder + label {
      top: 22px; }

.area-logo-bar__logo {
  width: auto;
  display: inline-block;
  margin: 0 15px;
  position: relative; }

.area-logo-bar__image {
  width: auto; }
  .area-logo-bar__image--color {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0; }
    .area-logo-bar__image--color:hover {
      opacity: 1; }
  .area-logo-bar__image--grey {
    opacity: 1; }
    .area-logo-bar__image--grey:hover {
      opacity: 0; }

.area-logo-bar .slick-arrow:before {
  content: ''; }

.area-logo-bar .slick-arrow.slick-prev {
  margin-left: -15px; }

.area-logo-bar .slick-arrow.slick-next {
  margin-right: -15px; }

.area-logo-bar .slick-arrow .icon-arrow-left_m,
.area-logo-bar .slick-arrow .icon-arrow-right_m {
  width: 20px;
  height: 20px; }
  .area-logo-bar .slick-arrow .icon-arrow-left_m use,
  .area-logo-bar .slick-arrow .icon-arrow-right_m use {
    fill: #eee; }

@media (min-width: 992px) {
  .area-logo-bar .slick-arrow .icon-arrow-left_m,
  .area-logo-bar .slick-arrow .icon-arrow-right_m {
    width: 15px;
    height: 15px; } }

.wysiwyg-special .brick:first-child {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.wysiwyg-special:first-child > section {
  border-right: 3px solid #e1ded7;
  padding-right: 4rem; }

.wysiwyg-special:nth-child(2) > section {
  padding-left: 2.5rem; }

.wysiwyg-special .brick__header {
  margin-bottom: 0; }
  .wysiwyg-special .brick__header--headline {
    margin-bottom: 0;
    padding-bottom: 3.2rem; }

.wysiwyg-special .brick__footer {
  margin-top: 3.7rem; }

.area-blurb svg {
  height: 64%;
  fill: #e30018;
  position: absolute;
  top: 0%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%); }

.blurb {
  margin-top: 3rem;
  padding-top: 3rem; }
  .blurb__headline {
    color: #482b1e;
    font-weight: bold;
    font-size: 1.3rem; }
  .blurb a {
    color: #e30018; }
  .blurb a:hover {
    text-decoration: none;
    color: #482b1e; }

.gallery__wrapper {
  position: relative;
  float: none;
  margin: 0 auto; }
  .gallery__wrapper .center-col {
    float: none;
    margin: 0 auto; }
  .gallery__wrapper .gallery__item p {
    margin-bottom: 0;
    margin-top: 1rem;
    margin-left: 0.4rem;
    color: #482b1e;
    line-height: 1.3;
    font-weight: bold; }
  .gallery__wrapper .gallery__item img {
    width: 100%; }
  @media (max-width: 1400px) {
    .gallery__wrapper .arrows {
      display: none; } }
  .gallery__wrapper .arrows .slick-prev {
    z-index: 1;
    background: url("/static/icomoon/SVG/arrow_left_m.svg") no-repeat;
    background-size: 1rem;
    height: 2rem;
    width: 1rem;
    position: absolute;
    top: 50%;
    left: -10%;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    opacity: 0.3; }
    .gallery__wrapper .arrows .slick-prev:before {
      content: none; }
  .gallery__wrapper .arrows .slick-next {
    z-index: 1;
    background: url("/static/icomoon/SVG/arrow_right_m.svg") no-repeat;
    background-size: 1rem;
    height: 2rem;
    width: 1rem;
    position: absolute;
    top: 50%;
    right: -10%;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    opacity: 0.3; }
    .gallery__wrapper .arrows .slick-next:before {
      content: none; }
  .gallery__wrapper .slick-slider {
    margin-bottom: 0; }
  .gallery__wrapper .slick-dots {
    position: static;
    bottom: auto; }
    .gallery__wrapper .slick-dots ul {
      margin: 0;
      padding: 0;
      list-style-type: none; }
    .gallery__wrapper .slick-dots li {
      margin: 0;
      width: 26px; }
    .gallery__wrapper .slick-dots li button:before {
      line-height: 1.25rem;
      color: #b7b6b0;
      opacity: 1;
      height: 0.75rem;
      width: 0.75rem;
      top: -0.0625rem;
      left: 0.1875rem; }
    .gallery__wrapper .slick-dots li button {
      border-radius: 50%;
      height: 1rem;
      width: 1rem; }
    .gallery__wrapper .slick-dots li.slick-active button {
      background-image: url("/static/icomoon/SVG/dot_navigation_big_m.svg");
      background-size: 0.8125rem;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.32; }
      .gallery__wrapper .slick-dots li.slick-active button:before {
        content: ""; }

.brick__header--headline .h1 {
  color: #e30018;
  font-size: 40px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 55px;
  margin: 0; }
  @media (min-width: 576px) {
    .brick__header--headline .h1 {
      font-size: 40px; } }
  @media (min-width: 768px) {
    .brick__header--headline .h1 {
      font-size: 40px; } }
  @media (min-width: 992px) {
    .brick__header--headline .h1 {
      font-size: 40px; } }

.brick__header--headline .h2 {
  color: #e30018;
  font-size: 28px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 38px;
  margin: 0; }
  @media (min-width: 576px) {
    .brick__header--headline .h2 {
      font-size: 28px; } }
  @media (min-width: 768px) {
    .brick__header--headline .h2 {
      font-size: 28px; } }
  @media (min-width: 992px) {
    .brick__header--headline .h2 {
      font-size: 28px; } }

.brick__header--headline .h3, .brick__header--headline .h4 {
  color: #482b1e; }

.brick__header--headline__beige {
  background-color: #f5f5ed;
  margin-bottom: 74px; }
  .brick__header--headline__beige .brick__header--headline-inner {
    line-height: 44px !important;
    color: #482b1e;
    font-size: 20px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 27px;
    margin: 0; }
    @media (min-width: 576px) {
      .brick__header--headline__beige .brick__header--headline-inner {
        font-size: 20px; } }
    @media (min-width: 768px) {
      .brick__header--headline__beige .brick__header--headline-inner {
        font-size: 20px; } }
    @media (min-width: 992px) {
      .brick__header--headline__beige .brick__header--headline-inner {
        font-size: 20px; } }

.brick__header--sub-headline .h1, .brick__header--sub-headline .h2 {
  color: #e30018;
  font-weight: 700; }

.brick__header--sub-headline .h3, .brick__header--sub-headline .h4 {
  color: #482b1e; }

.brick__header--info-text {
  color: #482b1e;
  margin-top: 2rem;
  font-weight: 700; }

.brick__header {
  margin-bottom: 40px; }

.area-section--grey-background {
  background-color: #f5f5ed; }

.area-contactperson {
  padding-bottom: 0; }
  .area-contactperson .brick__header {
    margin-bottom: 20px; }
    .area-contactperson .brick__header--headline-inner {
      margin-bottom: 74px; }
    .area-contactperson .brick__header--sub-headline-inner {
      color: #e30018;
      font-size: 28px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 38px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactperson .brick__header--sub-headline-inner {
          font-size: 28px; } }
      @media (min-width: 768px) {
        .area-contactperson .brick__header--sub-headline-inner {
          font-size: 28px; } }
      @media (min-width: 992px) {
        .area-contactperson .brick__header--sub-headline-inner {
          font-size: 28px; } }
    .area-contactperson .brick__header--info-text {
      margin-top: 20px !important;
      color: #482b1e;
      font-size: 18px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 24px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactperson .brick__header--info-text {
          font-size: 18px; } }
      @media (min-width: 768px) {
        .area-contactperson .brick__header--info-text {
          font-size: 18px; } }
      @media (min-width: 992px) {
        .area-contactperson .brick__header--info-text {
          font-size: 18px; } }
  .area-contactperson .contact-person__wrapper {
    margin: 0 auto; }
  .area-contactperson .contact-person__items {
    text-align: center;
    margin-bottom: 15px; }
  .area-contactperson .contact-person__text-top {
    text-align: center;
    margin-bottom: 76px; }
    .area-contactperson .contact-person__text-top strong {
      color: #482b1e;
      font-size: 18px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 24px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactperson .contact-person__text-top strong {
          font-size: 18px; } }
      @media (min-width: 768px) {
        .area-contactperson .contact-person__text-top strong {
          font-size: 18px; } }
      @media (min-width: 992px) {
        .area-contactperson .contact-person__text-top strong {
          font-size: 18px; } }
  .area-contactperson .contact-person__text-bottom {
    text-align: center;
    margin-bottom: 60px; }
    .area-contactperson .contact-person__text-bottom strong {
      color: #482b1e;
      font-size: 18px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 24px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactperson .contact-person__text-bottom strong {
          font-size: 18px; } }
      @media (min-width: 768px) {
        .area-contactperson .contact-person__text-bottom strong {
          font-size: 18px; } }
      @media (min-width: 992px) {
        .area-contactperson .contact-person__text-bottom strong {
          font-size: 18px; } }
  .area-contactperson .contact-person__bottom-line {
    border-bottom: 2px solid #eee; }
  .area-contactperson .contact-person .teaser-object {
    margin: 0 50px 30px; }
    .area-contactperson .contact-person .teaser-object__image-person {
      height: auto;
      object-fit: cover;
      width: 100%;
      margin-bottom: 30px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto; }
    .area-contactperson .contact-person .teaser-object__info-wrapper {
      display: flex;
      flex-direction: column; }
    .area-contactperson .contact-person .teaser-object__position {
      order: 3; }
      .area-contactperson .contact-person .teaser-object__position p {
        margin-bottom: 0; }
    .area-contactperson .contact-person .teaser-object__phone {
      order: 4;
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactperson .contact-person .teaser-object__phone {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-contactperson .contact-person .teaser-object__phone {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-contactperson .contact-person .teaser-object__phone {
          font-size: 15px; } }
    .area-contactperson .contact-person .teaser-object__sub-headline {
      order: 2;
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactperson .contact-person .teaser-object__sub-headline {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-contactperson .contact-person .teaser-object__sub-headline {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-contactperson .contact-person .teaser-object__sub-headline {
          font-size: 15px; } }
    .area-contactperson .contact-person .teaser-object__headline {
      color: #482b1e;
      font-size: 18px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 24px;
      margin: 0;
      order: 1; }
      @media (min-width: 576px) {
        .area-contactperson .contact-person .teaser-object__headline {
          font-size: 18px; } }
      @media (min-width: 768px) {
        .area-contactperson .contact-person .teaser-object__headline {
          font-size: 18px; } }
      @media (min-width: 992px) {
        .area-contactperson .contact-person .teaser-object__headline {
          font-size: 18px; } }
    .area-contactperson .contact-person .teaser-object__mail {
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      order: 5;
      overflow-wrap: break-word; }
      @media (min-width: 576px) {
        .area-contactperson .contact-person .teaser-object__mail {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-contactperson .contact-person .teaser-object__mail {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-contactperson .contact-person .teaser-object__mail {
          font-size: 15px; } }
    @media (min-width: 768px) and (max-width: 992px) {
      .area-contactperson .contact-person .teaser-object {
        margin: 0 15px 40px; } }
    @media (max-width: 480px) {
      .area-contactperson .contact-person .teaser-object {
        margin: 0 70px 40px; } }

@media (min-width: 768px) {
  .area-twocolumnwysiwyg .two-column-wysiwyg-right {
    padding-left: 5rem; }
    .area-twocolumnwysiwyg .two-column-wysiwyg-right:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      height: 100%;
      border-left: 2px solid #eee; } }
  @media screen and (min-width: 768px) and (max-width: 960px) {
    .area-twocolumnwysiwyg .two-column-wysiwyg-right {
      padding-left: 2rem; } }

@media (min-width: 768px) {
  .area-twocolumnwysiwyg .two-column-wysiwyg-left {
    padding-right: 5rem; } }

@media (max-width: 765px) {
  .area-twocolumnwysiwyg .two-column-wysiwyg-left {
    margin-bottom: 1rem; } }

.area-newsteaser {
  padding: 40px 0; }
  .area-newsteaser .h2 {
    color: #e30018;
    font-size: 28px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 38px;
    margin: 0; }
    @media (min-width: 576px) {
      .area-newsteaser .h2 {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .area-newsteaser .h2 {
        font-size: 28px; } }
    @media (min-width: 992px) {
      .area-newsteaser .h2 {
        font-size: 28px; } }
  .area-newsteaser .h1 {
    color: #e30018;
    font-size: 40px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 55px;
    margin: 0; }
    @media (min-width: 576px) {
      .area-newsteaser .h1 {
        font-size: 40px; } }
    @media (min-width: 768px) {
      .area-newsteaser .h1 {
        font-size: 40px; } }
    @media (min-width: 992px) {
      .area-newsteaser .h1 {
        font-size: 40px; } }
  .area-newsteaser .news-teaser__wrapper-outer {
    position: relative; }
  .area-newsteaser .news-teaser-item {
    display: flex; }
    .area-newsteaser .news-teaser-item__image {
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .area-newsteaser .news-teaser-item-info__title {
      color: #e30018;
      font-size: 20px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 27px;
      margin: 0;
      color: #482b1e; }
      @media (min-width: 576px) {
        .area-newsteaser .news-teaser-item-info__title {
          font-size: 20px; } }
      @media (min-width: 768px) {
        .area-newsteaser .news-teaser-item-info__title {
          font-size: 20px; } }
      @media (min-width: 992px) {
        .area-newsteaser .news-teaser-item-info__title {
          font-size: 20px; } }
    .area-newsteaser .news-teaser-item-info__date {
      color: #482b1e;
      font-size: 15px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      color: #482b1e; }
      @media (min-width: 576px) {
        .area-newsteaser .news-teaser-item-info__date {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-newsteaser .news-teaser-item-info__date {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-newsteaser .news-teaser-item-info__date {
          font-size: 15px; } }
    .area-newsteaser .news-teaser-item-info__text {
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      display: inline-block;
      margin-bottom: 10px;
      overflow: hidden;
      /*
        p {
          @include multiLineEllipsis($lineHeight: 26px, $lineCount: 3, $bgColor: white);
          margin-bottom: 10px;
        }
        */ }
      @media (min-width: 576px) {
        .area-newsteaser .news-teaser-item-info__text {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-newsteaser .news-teaser-item-info__text {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-newsteaser .news-teaser-item-info__text {
          font-size: 15px; } }
    .area-newsteaser .news-teaser-item__read-more--style {
      color: #482b1e;
      font-size: 15px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0;
      color: #e30018; }
      @media (min-width: 576px) {
        .area-newsteaser .news-teaser-item__read-more--style {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-newsteaser .news-teaser-item__read-more--style {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-newsteaser .news-teaser-item__read-more--style {
          font-size: 15px; } }
      .area-newsteaser .news-teaser-item__read-more--style .icon-rue_m {
        fill: #e30018;
        display: inline-block;
        margin-right: 8px; }
        @media only screen and (min-width: 768px) {
          .area-newsteaser .news-teaser-item__read-more--style .icon-rue_m {
            position: relative; } }
      .area-newsteaser .news-teaser-item__read-more--style:hover {
        text-decoration: none;
        color: #482b1e; }
        .area-newsteaser .news-teaser-item__read-more--style:hover .icon-rue_m {
          fill: #482b1e; }
  .area-newsteaser .news-teaser__items {
    display: flex; }
  .area-newsteaser .news-teaser__arrows {
    position: absolute;
    top: 60%;
    margin-top: -40px; }
    @media (max-width: 1400px) {
      .area-newsteaser .news-teaser__arrows {
        display: none; } }
    .area-newsteaser .news-teaser__arrows .news-prev {
      z-index: 1;
      background: url("/static/icomoon/SVG/arrow_left_m.svg") no-repeat;
      background-size: 1rem;
      height: 2rem;
      width: 1rem;
      left: -100px;
      position: relative;
      cursor: pointer;
      border: none;
      outline: none;
      opacity: 0.3; }
    .area-newsteaser .news-teaser__arrows .news-next {
      z-index: 1;
      background: url("/static/icomoon/SVG/arrow_right_m.svg") no-repeat;
      background-size: 1rem;
      height: 2rem;
      width: 1rem;
      left: 1200px;
      position: relative;
      cursor: pointer;
      border: none;
      outline: none;
      opacity: 0.3; }
  .area-newsteaser .cta-button-item a {
    color: #482b1e;
    font-size: 18px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 24px;
    margin: 0;
    color: white;
    padding: 10px 35px; }
    @media (min-width: 576px) {
      .area-newsteaser .cta-button-item a {
        font-size: 18px; } }
    @media (min-width: 768px) {
      .area-newsteaser .cta-button-item a {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .area-newsteaser .cta-button-item a {
        font-size: 18px; } }
  .area-newsteaser .brick__footer {
    margin-top: 60px; }

.area-contactpersonlist .brick__header {
  margin-bottom: 20px; }
  .area-contactpersonlist .brick__header--headline-inner {
    margin-bottom: 74px; }
  .area-contactpersonlist .brick__header--sub-headline-inner {
    color: #e30018;
    font-size: 28px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 38px;
    margin: 0; }
    @media (min-width: 576px) {
      .area-contactpersonlist .brick__header--sub-headline-inner {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .area-contactpersonlist .brick__header--sub-headline-inner {
        font-size: 28px; } }
    @media (min-width: 992px) {
      .area-contactpersonlist .brick__header--sub-headline-inner {
        font-size: 28px; } }
  .area-contactpersonlist .brick__header--info-text {
    margin-top: 20px !important;
    color: #482b1e;
    font-size: 18px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 24px;
    margin: 0; }
    @media (min-width: 576px) {
      .area-contactpersonlist .brick__header--info-text {
        font-size: 18px; } }
    @media (min-width: 768px) {
      .area-contactpersonlist .brick__header--info-text {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .area-contactpersonlist .brick__header--info-text {
        font-size: 18px; } }

.area-contactpersonlist .contact-person {
  margin: 15px 0; }
  .area-contactpersonlist .contact-person__items {
    text-align: center;
    margin-bottom: 60px; }
  .area-contactpersonlist .contact-person .teaser-object {
    display: flex; }
    .area-contactpersonlist .contact-person .teaser-object__info-wrapper {
      display: flex;
      flex-direction: column; }
    .area-contactpersonlist .contact-person .teaser-object__image-person {
      height: 100px;
      width: auto;
      border-radius: 50%;
      margin-right: 20px; }
    .area-contactpersonlist .contact-person .teaser-object__position {
      order: 4; }
      .area-contactpersonlist .contact-person .teaser-object__position p {
        margin-bottom: 0 !important; }
    .area-contactpersonlist .contact-person .teaser-object__phone {
      order: 5;
      display: block;
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactpersonlist .contact-person .teaser-object__phone {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-contactpersonlist .contact-person .teaser-object__phone {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-contactpersonlist .contact-person .teaser-object__phone {
          font-size: 15px; } }
    .area-contactpersonlist .contact-person .teaser-object__sub-headline {
      order: 3;
      display: block;
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactpersonlist .contact-person .teaser-object__sub-headline {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-contactpersonlist .contact-person .teaser-object__sub-headline {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-contactpersonlist .contact-person .teaser-object__sub-headline {
          font-size: 15px; } }
    .area-contactpersonlist .contact-person .teaser-object__headline {
      order: 2;
      display: block;
      color: #482b1e;
      font-size: 18px;
      font-weight: 700;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 24px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactpersonlist .contact-person .teaser-object__headline {
          font-size: 18px; } }
      @media (min-width: 768px) {
        .area-contactpersonlist .contact-person .teaser-object__headline {
          font-size: 18px; } }
      @media (min-width: 992px) {
        .area-contactpersonlist .contact-person .teaser-object__headline {
          font-size: 18px; } }
    .area-contactpersonlist .contact-person .teaser-object__mail {
      order: 6;
      display: block;
      color: #000000;
      font-size: 15px;
      font-weight: 400;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 26px;
      margin: 0; }
      @media (min-width: 576px) {
        .area-contactpersonlist .contact-person .teaser-object__mail {
          font-size: 15px; } }
      @media (min-width: 768px) {
        .area-contactpersonlist .contact-person .teaser-object__mail {
          font-size: 15px; } }
      @media (min-width: 992px) {
        .area-contactpersonlist .contact-person .teaser-object__mail {
          font-size: 15px; } }

@media (max-width: 480px) {
  .area-locations .locations-wrapper {
    text-align: center; } }

.area-locations .footer-contact {
  background-color: #e1ded7;
  padding: 75px 0; }
  .area-locations .footer-contact__address {
    color: #482b1e;
    font-weight: 600;
    font-size: 0.9375rem; }
    .area-locations .footer-contact__address img {
      margin-bottom: 15px; }
    @media (max-width: 768px) {
      .area-locations .footer-contact__address {
        margin: 0 0 40px; } }

.area-locations .location-address a:not(.email) {
  transition: all 300ms;
  color: #482b1e; }
  @media screen and (prefers-reduced-motion: reduce) {
    .area-locations .location-address a:not(.email) {
      transition: none; } }
  .area-locations .location-address a:not(.email):hover {
    text-decoration: none;
    color: #e30018;
    transition: all 300ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      .area-locations .location-address a:not(.email):hover {
        transition: none; } }

.area-map.map-shown {
  transition: all 300ms;
  margin-top: 60px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .area-map.map-shown {
      transition: none; } }

.area-map .finder__attributes {
  border-bottom: 1px solid transparent; }

.project-map {
  height: 465px;
  width: 100%;
  overflow: hidden; }

.map__title {
  color: #e30018;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 10px;
  display: block; }
  .map__title:hover {
    color: #e30018; }

.map__address, .map__price, .map__rooms {
  position: relative;
  font-size: 16px;
  margin: 10px 0;
  margin-left: 8px;
  font-weight: 500;
  left: 25px;
  height: 20px; }
  .map__address svg, .map__price svg, .map__rooms svg {
    height: 20px;
    width: 27px;
    top: -3px;
    position: absolute;
    left: -30px; }

.map__image {
  position: relative; }

.map__exit {
  height: 20px;
  width: 20px;
  position: absolute;
  display: block;
  right: 0;
  z-index: 1;
  font-size: 16px; }
  .map__exit:hover {
    cursor: pointer;
    color: #e30018; }

.map__price, .map__rooms {
  left: 30px; }
  .map__price svg, .map__rooms svg {
    left: -35px; }

.map__hr {
  height: 2px;
  width: 98%;
  background-color: #C4C3BE;
  position: relative;
  display: block;
  margin: 20px auto; }

.map__btn {
  position: relative;
  height: 40px; }
  .map__btn .btn {
    right: 8px;
    position: absolute;
    font-weight: 600; }

.popup__hidden {
  display: none !important; }

.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px; }

/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 40px;
  left: 0; }

/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white; }

/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
  min-width: 200px; }

.marker {
  height: 20px;
  width: 20px;
  background-image: url("/static/img/favicon/favicon-16x16.png"); }

.home header {
  height: 8.13rem;
  line-height: 8.13rem;
  padding-top: 0;
  transition: all 300ms; }
  @media screen and (prefers-reduced-motion: reduce) {
    .home header {
      transition: none; } }
  .home header :not(.is-top) .logo-wrapper {
    position: relative;
    text-align: center;
    width: 100%; }
    @media (min-width: 300px) {
      .home header :not(.is-top) .logo-wrapper {
        margin-left: 2rem; } }
    .home header :not(.is-top) .logo-wrapper a {
      width: 100%; }
    .home header :not(.is-top) .logo-wrapper__logo {
      width: 100%;
      max-height: 5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 500px) {
        .home header :not(.is-top) .logo-wrapper__logo {
          margin-top: 0; } }
  .home header .top-navigation-wrapper {
    padding-top: 0.5rem; }
  .home header.editmode {
    position: relative; }

body:not(.home) .logo-wrapper {
  text-align: center; }
  body:not(.home) .logo-wrapper a {
    width: 100%; }

body:not(.home) header.smart-hide:not(.is-top) {
  line-height: 0px; }
  body:not(.home) header.smart-hide:not(.is-top) .logo-wrapper__logo {
    opacity: 1; }
    @media (max-width: 500px) {
      body:not(.home) header.smart-hide:not(.is-top) .logo-wrapper__logo {
        top: 0.6rem;
        width: 100%;
        margin-left: 1rem; } }

@media (max-width: 500px) {
  body:not(.home) header.smart-hide.is-top .logo-wrapper__logo {
    top: 0.6rem;
    margin-left: 1rem; } }

header {
  position: fixed;
  top: 0;
  z-index: 200;
  max-width: 1920px;
  width: 100%;
  height: 60px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 25px rgba(40, 40, 40, 0.1);
  left: 50%;
  -webkit-transform: translate(-50%, 0) rotate(0);
  -ms-transform: translate(-50%, 0) rotate(0);
  -o-transform: translate(-50%, 0) rotate(0);
  transform: translate(-50%, 0) rotate(0);
  padding-top: 0.5rem;
  transition: all 300ms;
  /**
  ** CHANGE HEADER IMAGE DEPENDING ON /PATH
  */ }
  @media screen and (prefers-reduced-motion: reduce) {
    header {
      transition: none; } }
  header.animated {
    transition: all 250ms; }
    @media screen and (prefers-reduced-motion: reduce) {
      header.animated {
        transition: none; } }
  header.nav-up {
    top: -8.75rem; }
    header.nav-up.open {
      top: 0; }
  header .logo-wrapper a {
    display: inline-block; }
  header .logo-wrapper__logo {
    max-height: 100%;
    display: block;
    top: 0.6rem;
    position: relative;
    width: 100%;
    transition: opacity 250ms;
    opacity: 1; }
    @media screen and (prefers-reduced-motion: reduce) {
      header .logo-wrapper__logo {
        transition: none; } }
    header .logo-wrapper__logo--slim {
      transition: opacity 250ms;
      opacity: 0; }
      @media screen and (prefers-reduced-motion: reduce) {
        header .logo-wrapper__logo--slim {
          transition: none; } }
  header.top-navigation.nav-up {
    top: -130px; }
    header.top-navigation.nav-up.open {
      top: 0; }
  header.top-navigation .logo-wrapper {
    height: 110px; }
  header.top-navigation .main-navigation {
    margin-top: 20px; }
  header .top-navigation-wrapper {
    height: 40px;
    text-align: right;
    line-height: 40px; }
    header .top-navigation-wrapper .search-bar {
      display: inline-block; }
    header .top-navigation-wrapper .top-navigation {
      display: inline-block;
      position: relative; }
      header .top-navigation-wrapper .top-navigation__item {
        background-color: #e30018;
        border-color: #e30018;
        width: 6.5rem;
        height: 2.1rem;
        margin-right: 1.19rem;
        margin-left: 1.88rem; }
        header .top-navigation-wrapper .top-navigation__item a {
          color: white;
          font-size: 1.13rem;
          font-weight: bold;
          position: relative;
          top: -4px; }
    header .top-navigation-wrapper .language-switch {
      display: inline-block;
      position: relative;
      color: #b7b6b0; }
      header .top-navigation-wrapper .language-switch a {
        color: #b7b6b0; }
  header.smart-hide.is-top .logo-wrapper__logo {
    opacity: 1; }
    header.smart-hide.is-top .logo-wrapper__logo--slim {
      opacity: 0; }
  header.smart-hide:not(.is-top) {
    height: 60px;
    line-height: 60px; }
    header.smart-hide:not(.is-top) .logo-wrapper__logo {
      opacity: 0; }
      header.smart-hide:not(.is-top) .logo-wrapper__logo--slim {
        opacity: 1; }
    header.smart-hide:not(.is-top) ~ .header-stage {
      margin-top: 60px; }

.main-navigation__item--sub-navigation {
  display: none; }

footer {
  padding: 75px 0 0; }
  footer .footer-contact {
    background-color: #e1ded7;
    padding: 75px 0; }
    footer .footer-contact__address {
      color: #482b1e; }
      footer .footer-contact__address img {
        margin-bottom: 15px; }
  footer .footer-bottom {
    background: #e30018;
    background: linear-gradient(to right, #800c0f, #800c0f 0%, #a9000e);
    padding: 18px 0; }
    footer .footer-bottom .social-media-links a {
      color: #FFFFFF;
      font-size: 18px; }
      footer .footer-bottom .social-media-links a:hover {
        color: #482b1e; }
  footer .footer-legal-links ul {
    margin-bottom: 0; }
    footer .footer-legal-links ul li a {
      color: #FFFFFF;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      display: block; }
      footer .footer-legal-links ul li a:hover {
        text-decoration: none;
        color: #482b1e; }
    footer .footer-legal-links ul li:not(:last-child):not(:only-child) a:after {
      content: "|";
      display: inline-block;
      padding-left: 10px; }
  footer .footer-locations__item {
    color: #482b1e;
    font-weight: 600;
    font-size: 0.9375rem; }
    footer .footer-locations__item:not(.active) {
      display: none; }
    footer .footer-locations__item a:not(.email) {
      transition: all 300ms;
      color: #482b1e; }
      @media screen and (prefers-reduced-motion: reduce) {
        footer .footer-locations__item a:not(.email) {
          transition: none; } }
      footer .footer-locations__item a:not(.email):hover {
        text-decoration: none;
        color: #e30018;
        transition: all 300ms; }
        @media screen and (prefers-reduced-motion: reduce) {
          footer .footer-locations__item a:not(.email):hover {
            transition: none; } }
  footer .footer-locations__map {
    position: relative; }
    footer .footer-locations__map-pin {
      width: 15px;
      height: 15px;
      margin-left: -7.5px;
      margin-top: -7.5px;
      background-color: #482b1e;
      border-radius: 50%;
      transition: all 300ms;
      cursor: pointer;
      position: absolute; }
      @media screen and (prefers-reduced-motion: reduce) {
        footer .footer-locations__map-pin {
          transition: none; } }
      footer .footer-locations__map-pin:hover, footer .footer-locations__map-pin.active {
        background-color: #e30018;
        transition: all 300ms; }
        @media screen and (prefers-reduced-motion: reduce) {
          footer .footer-locations__map-pin:hover, footer .footer-locations__map-pin.active {
            transition: none; } }
      footer .footer-locations__map-pin.active .footer-locations__map-arrow {
        opacity: 1;
        visibility: visible;
        transition: all 300ms; }
        @media screen and (prefers-reduced-motion: reduce) {
          footer .footer-locations__map-pin.active .footer-locations__map-arrow {
            transition: none; } }
    footer .footer-locations__map-arrow {
      position: absolute;
      bottom: 15px;
      right: 15px;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      transition: all 300ms; }
      @media screen and (prefers-reduced-motion: reduce) {
        footer .footer-locations__map-arrow {
          transition: none; } }

.home section.header-stage {
  margin-top: 8.13rem; }

section.header-stage {
  padding: 0;
  margin-top: 60px;
  transition: margin-top 0.3s; }
  section.header-stage .stage-snippet {
    position: relative; }
    section.header-stage .stage-snippet--mainPage {
      height: calc(100vh - 130px); }
      section.header-stage .stage-snippet--mainPage .stage-snippet__slide-background {
        height: calc(100vh - 130px); }
      section.header-stage .stage-snippet--mainPage .stage-snippet__slide-video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /*
        min-width: 100%;
        min-height: 100%;
        left: 50%;
        @include translate-rotate(-50%, 0, 0);
        */ }
    section.header-stage .stage-snippet--searchPage {
      height: 15rem; }
      section.header-stage .stage-snippet--searchPage .stage-snippet__slide-background {
        height: 15rem; }
      section.header-stage .stage-snippet--searchPage .stage-snippet__slide-content-headline {
        top: -7rem;
        position: relative; }
      section.header-stage .stage-snippet--searchPage .stage-overlay {
        max-width: 60rem;
        height: auto;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%); }
      section.header-stage .stage-snippet--searchPage .search-object {
        bottom: 3rem; }
    section.header-stage .stage-snippet--objectPageNoTabs {
      height: 15rem; }
      @media (min-width: 768px) {
        section.header-stage .stage-snippet--objectPageNoTabs {
          height: 35rem; } }
      section.header-stage .stage-snippet--objectPageNoTabs .stage-snippet__slide-background {
        height: 15rem; }
        @media (min-width: 768px) {
          section.header-stage .stage-snippet--objectPageNoTabs .stage-snippet__slide-background {
            height: 35rem; } }
      section.header-stage .stage-snippet--objectPageNoTabs .stage-overlay {
        display: none; }
      section.header-stage .stage-snippet--objectPageNoTabs .search-object {
        display: none; }
    section.header-stage .stage-snippet--objectPage {
      height: 15rem; }
      @media (min-width: 768px) {
        section.header-stage .stage-snippet--objectPage {
          height: 35rem; } }
      section.header-stage .stage-snippet--objectPage .stage-snippet__slide-background {
        height: 15rem; }
        @media (min-width: 768px) {
          section.header-stage .stage-snippet--objectPage .stage-snippet__slide-background {
            height: 35rem; } }
      section.header-stage .stage-snippet--objectPage .stage-snippet__slide-content-headline {
        top: -7rem;
        position: relative; }
      section.header-stage .stage-snippet--objectPage .stage-overlay {
        display: none; }
      section.header-stage .stage-snippet--objectPage .search-object {
        display: none; }
      section.header-stage .stage-snippet--objectPage .stage-navigation__list {
        position: fixed;
        left: 0;
        width: 100%;
        background-color: #482b1e;
        text-align: center;
        max-width: 1920px;
        transform: translateX(-50%);
        left: 50%; }
        section.header-stage .stage-snippet--objectPage .stage-navigation__list li {
          font-size: 1.25rem;
          font-weight: bold; }
        section.header-stage .stage-snippet--objectPage .stage-navigation__list li:not(:last-child) {
          margin-right: 3rem;
          margin-top: 1rem;
          margin-bottom: 0.25rem; }
        section.header-stage .stage-snippet--objectPage .stage-navigation__list a {
          color: #e30018; }
      section.header-stage .stage-snippet--objectPage .stage-footer:hover {
        color: #e30018; }
    section.header-stage .stage-snippet__slider {
      padding: 0;
      margin: 0;
      height: 100%; }
      section.header-stage .stage-snippet__slider .slick-list {
        height: 100%; }
        section.header-stage .stage-snippet__slider .slick-list .slick-track {
          height: 100%; }
    section.header-stage .stage-snippet__slide {
      position: relative;
      height: 100%;
      outline: none; }
      section.header-stage .stage-snippet__slide-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover; }
      section.header-stage .stage-snippet__slide-content {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        padding: 40px;
        top: 50%;
        transform: translateY(-50%); }
        section.header-stage .stage-snippet__slide-content-subHeadline {
          font-size: 35px;
          color: #FFFFFF; }
        section.header-stage .stage-snippet__slide-content-headline {
          font-size: 45px;
          color: #e30018; }
        section.header-stage .stage-snippet__slide-content .stage-snippet__slide-buttons .cta-button-item {
          margin: 0 10px;
          text-align: center; }
        section.header-stage .stage-snippet__slide-content .stage-snippet__slide-quick-links {
          margin-top: 40px; }
          section.header-stage .stage-snippet__slide-content .stage-snippet__slide-quick-links .quick-link-item {
            width: 120px;
            margin: 10px; }
            section.header-stage .stage-snippet__slide-content .stage-snippet__slide-quick-links .quick-link-item__icon {
              text-align: center;
              margin-bottom: 10px; }
              section.header-stage .stage-snippet__slide-content .stage-snippet__slide-quick-links .quick-link-item__icon svg {
                width: 80px;
                height: 80px;
                color: #FFFFFF; }
            section.header-stage .stage-snippet__slide-content .stage-snippet__slide-quick-links .quick-link-item__text {
              text-align: center;
              color: #FFFFFF; }
    section.header-stage .stage-snippet__slide-down-button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 25px;
      cursor: pointer; }
      section.header-stage .stage-snippet__slide-down-button svg {
        width: 60px;
        height: 60px;
        color: #FFFFFF; }
    section.header-stage .stage-snippet .slick-dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      padding: 0;
      z-index: 100; }
      section.header-stage .stage-snippet .slick-dots li {
        display: inline-block; }
        section.header-stage .stage-snippet .slick-dots li button {
          width: 12px;
          height: 12px;
          background: #FFFFFF;
          border-radius: 12px;
          font-size: 1px;
          text-indent: -9999px;
          margin: 0 3px;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none; }
        section.header-stage .stage-snippet .slick-dots li.slick-active button {
          background: #e30018; }

#siteWrapper.top-navigation section.header-stage .stage-snippet--full {
  height: calc(100vh - 130px); }
  #siteWrapper.top-navigation section.header-stage .stage-snippet--full .stage-snippet__slide-background {
    height: calc(100vh - 130px); }

.stage-overlay {
  position: absolute;
  height: auto;
  bottom: 0;
  left: 0;
  right: 0;
  transition: .5s ease;
  background-color: rgba(245, 245, 237, 0.8);
  z-index: 1; }
  @media (max-width: 991.98px) {
    .stage-overlay {
      font-size: 13px; } }

.search-object {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  bottom: -3rem; }
  .search-object ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #b7b6b0; }
  .search-object ::-moz-placeholder {
    /* Firefox 19+ */
    color: #b7b6b0; }
  .search-object :-ms-input-placeholder {
    /* IE 10+ */
    color: #b7b6b0; }
  .search-object :-moz-placeholder {
    /* Firefox 18- */
    color: #b7b6b0; }
  .search-object-button {
    background-color: #e30018;
    border-color: #e30018;
    color: white;
    font-size: 1.13rem;
    font-weight: bold; }
  .search-object input {
    height: 40px; }

.project-page__tab-navigation {
  padding-bottom: 30px; }
  .project-page__tab-navigation .nav-tabs {
    border: none;
    background: #482b1e;
    padding: 10px 0; }
    @media (min-width: 992px) {
      .project-page__tab-navigation .nav-tabs {
        padding: 0; } }
    .project-page__tab-navigation .nav-tabs .nav-item {
      background: transparent;
      border: none; }
      .project-page__tab-navigation .nav-tabs .nav-item .nav-link {
        font-weight: bold;
        padding: 10px 25px;
        line-height: 1;
        color: white;
        background: transparent;
        border: none; }
        @media (min-width: 992px) {
          .project-page__tab-navigation .nav-tabs .nav-item .nav-link {
            padding: 20px 25px;
            font-size: 22px; } }
        .project-page__tab-navigation .nav-tabs .nav-item .nav-link:hover {
          color: #e30018; }
        .project-page__tab-navigation .nav-tabs .nav-item .nav-link.active {
          color: #e30018; }

.project-info {
  margin: 30px 0; }
  .project-info span.icon {
    width: 45px;
    height: 45px; }
    .project-info span.icon svg {
      vertical-align: top;
      fill: #e30018; }
  .project-info__name {
    font-size: 32px;
    font-weight: bold;
    color: #e30018; }
  .project-info__location {
    font-size: 22px;
    color: #482b1e; }

.project-briefing {
  margin: 30px 0 1px;
  border-radius: 5px;
  background: #f5f5ed;
  border: 1px solid #e3e3cc;
  padding: 30px 0;
  font-size: 0.93rem;
  font-weight: bold; }
  .project-briefing span.icon {
    width: 40px;
    height: 40px;
    margin-right: 7px; }
    @media (min-width: 768px) {
      .project-briefing span.icon {
        width: 45px;
        height: 45px;
        margin-right: 0; } }
    .project-briefing span.icon svg {
      vertical-align: top;
      fill: #e30018; }
  .project-briefing__image {
    width: 45px !important;
    height: 40px;
    width: auto;
    margin-left: -5px; }
    @media (min-width: 768px) {
      .project-briefing__image {
        width: 38px !important;
        height: 45px;
        margin-left: 0; } }
    @media (min-width: 992px) {
      .project-briefing__image {
        width: 45px !important; } }
  .project-briefing__item {
    justify-content: center; }
    .project-briefing__item-info {
      width: 100%; }
    @media (max-width: 768px) {
      .project-briefing__item {
        margin-bottom: 15px;
        justify-content: flex-start; } }
    @media (max-width: 576px) {
      .project-briefing__item {
        padding-left: 15%; } }
    @media (min-width: 768px) and (max-width: 992px) {
      .project-briefing__item {
        margin-bottom: 15px; }
        .project-briefing__item--first-item {
          padding-bottom: 15px;
          justify-content: flex-start; }
        .project-briefing__item--last-item {
          justify-content: flex-end; } }

body:not(.home) header.is-top .mobile-menu-button {
  margin-top: 0.5rem; }

body:not(.home) header:not(.is-top) .mobile-menu-button {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: color 0 ease-in;
  transition: none;
  margin-top: 0.5rem; }

.mobile-menu-button {
  z-index: 10;
  width: 30px;
  height: 30px;
  position: relative;
  margin-left: 1.25rem;
  transform: translate(0, -50%) rotate(0deg);
  top: 50%;
  transition: 0.5s ease-in-out;
  cursor: pointer; }
  @media screen and (prefers-reduced-motion: reduce) {
    .mobile-menu-button {
      transition: none; } }
  .mobile-menu-button span {
    border-radius: 6px;
    left: 0;
    display: block;
    position: absolute;
    height: 6px;
    width: 50%;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    background-color: #e30018; }
    @media screen and (prefers-reduced-motion: reduce) {
      .mobile-menu-button span {
        transition: none; } }
    .mobile-menu-button span:nth-child(even) {
      left: 50%;
      border-radius: 0; }
    .mobile-menu-button span:nth-child(odd) {
      left: 0;
      border-radius: 0; }
    .mobile-menu-button span:nth-child(1), .mobile-menu-button span:nth-child(2) {
      top: 0;
      height: 0.4rem; }
    .mobile-menu-button span:nth-child(3), .mobile-menu-button span:nth-child(4) {
      top: 10.8px;
      height: 0.35rem; }
    .mobile-menu-button span:nth-child(5), .mobile-menu-button span:nth-child(6) {
      top: 21px;
      height: 0.4rem; }

body.mobile-menu-open .mobile-menu-button {
  opacity: 0; }

.mobile-navigation {
  background-color: #482b1e;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0 0 10px 0 rgba(238, 238, 238, 0.3);
  padding: 15px 0;
  width: 300px;
  max-height: 100vh;
  z-index: 220;
  transition: transform 0.4s ease-out;
  transform: translate(-120%, 0); }
  .mobile-navigation__close-helper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 219; }
  .mobile-navigation__nav {
    color: #f5f5ed;
    margin-top: 15rem; }
    @media (max-height: 1000px) {
      .mobile-navigation__nav {
        margin-top: 5rem; } }
    .mobile-navigation__nav ul.navigation.main-navigation__list {
      padding-left: 30px; }
    .mobile-navigation__nav .main-navigation__item {
      padding: 0.6rem 0.75rem;
      padding-left: 0px; }
      .mobile-navigation__nav .main-navigation__item a {
        font-weight: bold;
        font-size: 1.3rem;
        color: #f5f5ed; }
        .mobile-navigation__nav .main-navigation__item a.open {
          color: #e30018; }
      .mobile-navigation__nav .main-navigation__item a:hover {
        color: #e30018;
        text-decoration: none; }
    .mobile-navigation__nav .main-navigation__item:hover {
      color: #e30018; }
    .mobile-navigation__nav .main-navigation__single-page-link a {
      font-size: 1.3rem; }
    .mobile-navigation__nav .main-navigation__list {
      list-style: none; }
    .mobile-navigation__nav .main-navigation__item--sub-navigation {
      padding-top: 0.75rem;
      padding-left: 1rem;
      line-height: 1.5rem; }
    .mobile-navigation__nav .main-navigation__sub-navigation-item {
      padding-top: 0;
      padding-bottom: 0;
      font-weight: normal;
      margin-left: 1rem; }
    .mobile-navigation__nav .main-navigation__sub-navigation-item a {
      font-weight: normal;
      margin-top: 0.1rem;
      font-size: 1rem; }
  .mobile-navigation__cross {
    position: absolute;
    top: 10px;
    left: 20px;
    cursor: pointer;
    font-size: 2rem; }
  .mobile-navigation.is-top.is-home .mobile-navigation__cross {
    top: 40px; }
  .mobile-navigation .navigation {
    padding-bottom: 20px;
    max-height: 70vh;
    overflow-y: hidden; }
  .mobile-navigation .navigation:hover {
    overflow-y: auto; }

body.mobile-menu-open .mobile-navigation {
  transform: translate(0%, 0); }
  body.mobile-menu-open .mobile-navigation__close-helper {
    display: block; }

body.mobile-menu-open .social-media-wrapper {
  padding-left: 20px;
  position: absolute;
  bottom: 0; }
  body.mobile-menu-open .social-media-wrapper :not(:last-child) {
    margin-right: 0.5rem; }

body.mobile-menu-open .social-media-link {
  border: 1px solid #e30018;
  background-color: #e30018;
  border-radius: 50%;
  width: 2.25rem;
  height: 2.25rem; }
  body.mobile-menu-open .social-media-link svg {
    width: 1.5rem;
    height: 1.5rem;
    color: #f5f5ed; }
  body.mobile-menu-open .social-media-link a {
    position: relative;
    top: 0.3rem;
    left: 0.3rem; }
  body.mobile-menu-open .social-media-link a:hover {
    color: #482b1e; }

.footer-contact-form .form-control {
  border-radius: 0;
  border: none; }
  .footer-contact-form .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: 0; }

.footer-contact-form form label {
  display: none; }

.footer-contact-form form [for="footer_contact_privacy"],
.footer-contact-form form [for="footer_contact_contactConsent"] {
  display: inline-block;
  margin-left: 4px;
  margin-top: 4px; }

.footer-contact-form form input {
  border-radius: 0;
  border: none; }
  .footer-contact-form form input:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: 0; }

.footer-contact-form .datasecurity {
  position: relative;
  top: 0;
  left: 0;
  color: black;
  font-size: 0.75rem; }

.newsPage {
  margin-top: 10rem; }
  .newsPage--header {
    color: #e30018; }
  .newsPage .h1, .newsPage .h2 {
    color: #e30018; }
  .newsPage .h3, .newsPage .h4 {
    color: #482b1e; }
  .newsPage img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.news-overview__wrapper {
  padding: 150px 0 75px; }
  .news-overview__wrapper .news-overview__inner {
    padding: 15px; }
    .news-overview__wrapper .news-overview__inner--border {
      border: solid 0.8px #e1ded6; }
  .news-overview__wrapper .news-overview__image {
    object-fit: cover;
    height: auto;
    width: 100%; }
  .news-overview__wrapper .news-overview__title {
    color: #e30018;
    font-size: 20px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 27px;
    margin: 0;
    color: #482b1e; }
    @media (min-width: 576px) {
      .news-overview__wrapper .news-overview__title {
        font-size: 20px; } }
    @media (min-width: 768px) {
      .news-overview__wrapper .news-overview__title {
        font-size: 20px; } }
    @media (min-width: 992px) {
      .news-overview__wrapper .news-overview__title {
        font-size: 20px; } }
  .news-overview__wrapper .news-overview__date {
    color: #482b1e;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 26px;
    margin: 0;
    color: #482b1e; }
    @media (min-width: 576px) {
      .news-overview__wrapper .news-overview__date {
        font-size: 15px; } }
    @media (min-width: 768px) {
      .news-overview__wrapper .news-overview__date {
        font-size: 15px; } }
    @media (min-width: 992px) {
      .news-overview__wrapper .news-overview__date {
        font-size: 15px; } }
  .news-overview__wrapper .news-overview__text {
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 26px;
    margin: 0; }
    @media (min-width: 576px) {
      .news-overview__wrapper .news-overview__text {
        font-size: 15px; } }
    @media (min-width: 768px) {
      .news-overview__wrapper .news-overview__text {
        font-size: 15px; } }
    @media (min-width: 992px) {
      .news-overview__wrapper .news-overview__text {
        font-size: 15px; } }
    .news-overview__wrapper .news-overview__text p {
      overflow: hidden;
      position: relative;
      line-height: 26px;
      max-height: 78px;
      padding-right: 1em;
      margin-bottom: 10px; }
      .news-overview__wrapper .news-overview__text p:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0; }
      .news-overview__wrapper .news-overview__text p:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: white; }
  .news-overview__wrapper .news-overview__read-more--style {
    color: #482b1e;
    font-size: 15px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 26px;
    margin: 0;
    color: #e30018; }
    @media (min-width: 576px) {
      .news-overview__wrapper .news-overview__read-more--style {
        font-size: 15px; } }
    @media (min-width: 768px) {
      .news-overview__wrapper .news-overview__read-more--style {
        font-size: 15px; } }
    @media (min-width: 992px) {
      .news-overview__wrapper .news-overview__read-more--style {
        font-size: 15px; } }
    .news-overview__wrapper .news-overview__read-more--style .icon-rue_m {
      fill: #e30018;
      display: inline-block;
      margin-right: 16px; }
      @media only screen and (min-width: 768px) {
        .news-overview__wrapper .news-overview__read-more--style .icon-rue_m {
          position: relative;
          top: 1.5px; } }
    .news-overview__wrapper .news-overview__read-more--style:hover {
      text-decoration: none;
      color: #482b1e; }
      .news-overview__wrapper .news-overview__read-more--style:hover .icon-rue_m {
        fill: #482b1e; }

#finder .reset span {
  display: block;
  float: left; }
  #finder .reset span.icon {
    width: 8px;
    height: 8px;
    margin-top: -2px;
    margin-right: 5px; }
  #finder .reset span.resetFilter {
    cursor: pointer;
    display: flex;
    float: right; }
    @media (min-width: 1200px) {
      #finder .reset span.resetFilter {
        float: left;
        margin: 10px 5px; } }

.finder__search, .property-list__search {
  padding: 30px 0; }

.finder__attributes, .property-list__attributes {
  padding: 45px 0 35px 0;
  border-bottom: 1px solid #eee;
  margin-bottom: 35px; }
  .finder__attributes .show-map .text, .property-list__attributes .show-map .text {
    display: block;
    float: right;
    line-height: 40px; }
  .finder__attributes .show-map .control, .property-list__attributes .show-map .control {
    display: block;
    float: right;
    margin-top: -6px;
    margin-left: 10px; }
  .finder__attributes .show-map input[type=checkbox], .property-list__attributes .show-map input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden; }
  .finder__attributes .show-map label, .property-list__attributes .show-map label {
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 8px;
    background: #eee;
    display: block;
    border-radius: 8px;
    position: relative; }
  .finder__attributes .show-map label:after, .property-list__attributes .show-map label:after {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 16px;
    height: 16px;
    background: #e30018;
    border-radius: 16px;
    transition: 0.3s;
    -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35); }
  .finder__attributes .show-map input:checked + label, .property-list__attributes .show-map input:checked + label {
    background: #F18289; }
  .finder__attributes .show-map input:checked + label:after, .property-list__attributes .show-map input:checked + label:after {
    left: calc(100%);
    transform: translateX(-100%); }
  .finder__attributes .show-map label:active:after, .property-list__attributes .show-map label:active:after {
    width: 30px; }
  .finder__attributes input.input, .property-list__attributes input.input {
    width: 100%;
    line-height: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid #C4C3BE;
    border-radius: 0.25rem;
    text-align: center;
    color: #000000; }
  .finder__attributes .bootstrap-select, .property-list__attributes .bootstrap-select {
    width: 100% !important;
    padding: 0;
    margin: 0; }
  .finder__attributes .bootstrap-select .btn-light.dropdown-toggle, .property-list__attributes .bootstrap-select .btn-light.dropdown-toggle {
    border: 1px solid #C4C3BE;
    width: 100%;
    margin: 0 0 2px 0;
    padding: 0; }
    @media (min-width: 1200px) {
      .finder__attributes .bootstrap-select .btn-light.dropdown-toggle, .property-list__attributes .bootstrap-select .btn-light.dropdown-toggle {
        margin: 0; } }
  .finder__attributes .bootstrap-select .dropdown-toggle .filter-option, .property-list__attributes .bootstrap-select .dropdown-toggle .filter-option {
    margin: 0;
    padding: 0; }
  .finder__attributes .dropdown-toggle .filter-option, .property-list__attributes .dropdown-toggle .filter-option {
    position: relative; }
  .finder__attributes .filter-option-inner-inner, .property-list__attributes .filter-option-inner-inner {
    font-style: normal;
    text-align: center;
    font-size: 1rem;
    line-height: 40px; }
  .finder__attributes .dropdown-toggle::after, .property-list__attributes .dropdown-toggle::after {
    display: none; }
  .finder__attributes .bootstrap-select .dropdown-toggle:before, .property-list__attributes .bootstrap-select .dropdown-toggle:before {
    display: none; }
  .finder__attributes .bootstrap-select .dropdown-menu, .property-list__attributes .bootstrap-select .dropdown-menu {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    border-radius: 0.25rem; }
  .finder__attributes .dropdown-item.active, .finder__attributes .dropdown-item:active, .property-list__attributes .dropdown-item.active, .property-list__attributes .dropdown-item:active {
    background: #fff;
    color: #000000; }
  .finder__attributes .bootstrap-select .dropdown-menu li a:hover, .property-list__attributes .bootstrap-select .dropdown-menu li a:hover {
    background: #F7F6F1; }

.finder__results .top,
.property-list .top,
.project-property-export-admin__results-wrapper .top {
  padding-bottom: 30px; }

.finder__results .head,
.property-list .head,
.project-property-export-admin__results-wrapper .head {
  background: #F7F6F1;
  padding: 10px 15px;
  font-weight: bold;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .finder__results .head .col-result > .row > div,
    .property-list .head .col-result > .row > div,
    .project-property-export-admin__results-wrapper .head .col-result > .row > div {
      display: flex;
      justify-content: center; }
      .finder__results .head .col-result > .row > div.col-result__project, .finder__results .head .col-result > .row > div.col-result__projectLocation, .finder__results .head .col-result > .row > div.col-result__number,
      .property-list .head .col-result > .row > div.col-result__project,
      .property-list .head .col-result > .row > div.col-result__projectLocation,
      .property-list .head .col-result > .row > div.col-result__number,
      .project-property-export-admin__results-wrapper .head .col-result > .row > div.col-result__project,
      .project-property-export-admin__results-wrapper .head .col-result > .row > div.col-result__projectLocation,
      .project-property-export-admin__results-wrapper .head .col-result > .row > div.col-result__number {
        justify-content: flex-start; }
      .finder__results .head .col-result > .row > div.col-result__livingSpace, .finder__results .head .col-result > .row > div.col-result__price,
      .property-list .head .col-result > .row > div.col-result__livingSpace,
      .property-list .head .col-result > .row > div.col-result__price,
      .project-property-export-admin__results-wrapper .head .col-result > .row > div.col-result__livingSpace,
      .project-property-export-admin__results-wrapper .head .col-result > .row > div.col-result__price {
        justify-content: flex-end; } }
  .finder__results .head .click,
  .property-list .head .click,
  .project-property-export-admin__results-wrapper .head .click {
    cursor: pointer; }
    .finder__results .head .click span,
    .property-list .head .click span,
    .project-property-export-admin__results-wrapper .head .click span {
      float: left; }
    .finder__results .head .click.active,
    .property-list .head .click.active,
    .project-property-export-admin__results-wrapper .head .click.active {
      color: #e30018; }
  .finder__results .head .title,
  .property-list .head .title,
  .project-property-export-admin__results-wrapper .head .title {
    margin-right: 5px; }
  .finder__results .head .asc svg, .finder__results .head .desc svg,
  .property-list .head .asc svg,
  .property-list .head .desc svg,
  .project-property-export-admin__results-wrapper .head .asc svg,
  .project-property-export-admin__results-wrapper .head .desc svg {
    fill: #e30018; }
  .finder__results .head .asc,
  .property-list .head .asc,
  .project-property-export-admin__results-wrapper .head .asc {
    float: left;
    margin-top: 7px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }
  .finder__results .head .desc,
  .property-list .head .desc,
  .project-property-export-admin__results-wrapper .head .desc {
    float: left;
    margin-top: 1px; }

.finder__results .results .property,
.property-list .results .property,
.project-property-export-admin__results-wrapper .results .property {
  display: block;
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid #D1C2BF; }
  .finder__results .results .property.open,
  .property-list .results .property.open,
  .project-property-export-admin__results-wrapper .results .property.open {
    background: #F7F6F1; }
  .finder__results .results .property .clickable,
  .property-list .results .property .clickable,
  .project-property-export-admin__results-wrapper .results .property .clickable {
    cursor: pointer;
    padding: 12px 0; }
    .finder__results .results .property .clickable .icon-minus,
    .property-list .results .property .clickable .icon-minus,
    .project-property-export-admin__results-wrapper .results .property .clickable .icon-minus {
      display: none; }
  .finder__results .results .property .details,
  .property-list .results .property .details,
  .project-property-export-admin__results-wrapper .results .property .details {
    padding: 45px 0 15px; }
    @media (min-width: 992px) {
      .finder__results .results .property .details,
      .property-list .results .property .details,
      .project-property-export-admin__results-wrapper .results .property .details {
        padding: 45px 39px 15px 0; } }
    .finder__results .results .property .details .location,
    .property-list .results .property .details .location,
    .project-property-export-admin__results-wrapper .results .property .details .location {
      padding-bottom: 25px;
      display: flex; }
      @media (max-width: 575.98px) {
        .finder__results .results .property .details .location,
        .property-list .results .property .details .location,
        .project-property-export-admin__results-wrapper .results .property .details .location {
          margin-top: 10px; } }
      .finder__results .results .property .details .location span,
      .property-list .results .property .details .location span,
      .project-property-export-admin__results-wrapper .results .property .details .location span {
        display: block;
        float: left; }
        .finder__results .results .property .details .location span.icon,
        .property-list .results .property .details .location span.icon,
        .project-property-export-admin__results-wrapper .results .property .details .location span.icon {
          width: 35px;
          height: 35px; }
        .finder__results .results .property .details .location span.address,
        .property-list .results .property .details .location span.address,
        .project-property-export-admin__results-wrapper .results .property .details .location span.address {
          color: #482b1e;
          font-weight: bold;
          padding: 4px 0 0 5px; }
        .finder__results .results .property .details .location span svg,
        .property-list .results .property .details .location span svg,
        .project-property-export-admin__results-wrapper .results .property .details .location span svg {
          fill: #e30018; }
    .finder__results .results .property .details .buttons,
    .property-list .results .property .details .buttons,
    .project-property-export-admin__results-wrapper .results .property .details .buttons {
      padding-bottom: 15px; }
    .finder__results .results .property .details .detail-prices,
    .property-list .results .property .details .detail-prices,
    .project-property-export-admin__results-wrapper .results .property .details .detail-prices {
      padding-bottom: 15px; }
    .finder__results .results .property .details .floorplan-image,
    .property-list .results .property .details .floorplan-image,
    .project-property-export-admin__results-wrapper .results .property .details .floorplan-image {
      padding-bottom: 15px; }
    .finder__results .results .property .details .project,
    .property-list .results .property .details .project,
    .project-property-export-admin__results-wrapper .results .property .details .project {
      padding-top: 15px; }
      .finder__results .results .property .details .project a.btn,
      .property-list .results .property .details .project a.btn,
      .project-property-export-admin__results-wrapper .results .property .details .project a.btn {
        font-weight: bold;
        line-height: 28px; }
    .finder__results .results .property .details a.fancybox,
    .property-list .results .property .details a.fancybox,
    .project-property-export-admin__results-wrapper .results .property .details a.fancybox {
      display: inline-block;
      position: relative; }
      .finder__results .results .property .details a.fancybox .zoom,
      .property-list .results .property .details a.fancybox .zoom,
      .project-property-export-admin__results-wrapper .results .property .details a.fancybox .zoom {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 31px;
        height: 31px;
        background: #e30018; }
        .finder__results .results .property .details a.fancybox .zoom .icon,
        .property-list .results .property .details a.fancybox .zoom .icon,
        .project-property-export-admin__results-wrapper .results .property .details a.fancybox .zoom .icon {
          display: block;
          margin: 0 auto;
          margin-top: 4px; }
        .finder__results .results .property .details a.fancybox .zoom svg,
        .property-list .results .property .details a.fancybox .zoom svg,
        .project-property-export-admin__results-wrapper .results .property .details a.fancybox .zoom svg {
          fill: #FFFFFF; }

.finder__results .col-result-first,
.property-list .col-result-first,
.project-property-export-admin__results-wrapper .col-result-first {
  width: 5%;
  text-align: center; }
  @media (max-width: 767.98px) {
    .finder__results .col-result-first > div,
    .property-list .col-result-first > div,
    .project-property-export-admin__results-wrapper .col-result-first > div {
      margin-left: -14px; } }
  .finder__results .col-result-first span,
  .property-list .col-result-first span,
  .project-property-export-admin__results-wrapper .col-result-first span {
    float: left;
    margin-left: 15px; }
    .finder__results .col-result-first span svg,
    .property-list .col-result-first span svg,
    .project-property-export-admin__results-wrapper .col-result-first span svg {
      fill: #e30018; }

.finder__results .col-result,
.property-list .col-result,
.project-property-export-admin__results-wrapper .col-result {
  width: 90%; }

.finder__results .col-result-last,
.property-list .col-result-last,
.project-property-export-admin__results-wrapper .col-result-last {
  width: 5%;
  text-align: center; }
  .finder__results .col-result-last span,
  .property-list .col-result-last span,
  .project-property-export-admin__results-wrapper .col-result-last span {
    margin: 0 auto; }

.location.bootstrap-select .dropdown-item span.text {
  margin-left: 10px; }

.location.bootstrap-select .dropdown-item:before {
  content: "";
  background-repeat: no-repeat;
  display: inline-block;
  height: 1em;
  width: 15px;
  vertical-align: text-top;
  background-size: contain;
  background-position: center center; }

.location.bootstrap-select .dropdown-item.project:before {
  background-image: url("/static/icomoon/SVG/haus_m.svg"); }

.location.bootstrap-select .dropdown-item.project-title:before {
  background-image: url("/static/icomoon/SVG/wohnimmobilie_m.svg"); }

.location.bootstrap-select .dropdown-item.region:before {
  background-image: url("/static/icomoon/SVG/wohnflaeche_m.svg"); }

.location.bootstrap-select .dropdown-menu.inner li a.level-2 {
  padding-left: 3rem; }

.property-search__results {
  position: relative;
  min-height: 200px; }
  @media (min-width: 1200px) {
    .property-search__results {
      min-height: 500px; } }
  .property-search__results .sk-folding-cube {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px); }

.sk-folding-cube {
  display: none;
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg); }

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #e30018;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%; }

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg); }

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg); }

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg); }

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s; }

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s; }

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

.property-list .results .project {
  display: none; }

.mobile-labels {
  color: #777; }

@media (max-width: 767.98px) {
  .floorplan-image {
    display: none; }
  #finder .finder__results .col-result-first span.icon {
    margin-left: 0; } }

.similiar-objects__headline {
  color: #e30018;
  margin-top: 60px; }

#similiarObjects .resultList .property:nth-child(n+7) {
  display: none; }

.divider-after:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 100%;
  width: 2px;
  border-left: white 2px solid;
  right: -1px; }

.natural-search {
  position: relative;
  padding: 15px 0;
  width: 48%; }
  .natural-search__inner {
    max-width: 90%;
    position: relative;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .natural-search__inner {
        max-width: 95%; } }
    @media screen and (min-width: 1600px) {
      .natural-search__inner {
        max-width: 40.625rem; } }
  .natural-search__headline {
    font-weight: 700;
    font-size: 1.1rem;
    color: #e30018;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .natural-search__headline {
        font-size: 30px; } }
  .natural-search__text {
    vertical-align: sub; }
    @media (min-width: 992px) {
      .natural-search__text {
        font-size: 20px;
        line-height: 2; } }
  .natural-search__select-wrapper {
    position: relative;
    display: inline-block; }
    .natural-search__select-wrapper span.icon {
      width: 12px;
      height: 12px;
      right: 6px;
      bottom: 9px;
      position: absolute; }

.quick-search {
  position: relative;
  padding: 15px 0;
  width: 50%;
  z-index: 10; }
  .quick-search__inner {
    max-width: 90%;
    position: relative;
    margin: 0 auto; }
    @media (min-width: 1200px) {
      .quick-search__inner {
        max-width: 50%; } }
  .quick-search__headline {
    font-weight: 700;
    font-size: 1.1rem;
    color: #e30018;
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .quick-search__headline {
        font-size: 30px; } }
  .quick-search__region {
    width: 100%;
    outline: none;
    box-shadow: none;
    border-radius: 4px;
    border: none; }
    .quick-search__region:focus, .quick-search__region:hover {
      outline: none;
      box-shadow: none; }
    .quick-search__region.bootstrap-select {
      width: 100% !important; }
      .quick-search__region.bootstrap-select .dropdown-menu.show {
        min-width: unset !important;
        width: 100% !important;
        transform: translate(0) !important; }
  .quick-search__submit {
    margin-top: 10px; }

.natural-search__select:focus, .quick-search__select:focus {
  outline: none !important;
  box-shadow: none !important; }

.natural-search__select .dropdown-toggle:focus, .quick-search__select .dropdown-toggle:focus {
  outline: none !important;
  box-shadow: none !important; }

.natural-search__select .dropdown-toggle:after, .quick-search__select .dropdown-toggle:after {
  margin: 0;
  vertical-align: unset;
  position: absolute;
  bottom: 5px;
  right: 0; }

.natural-search__select .dropdown-toggle .filter-option, .quick-search__select .dropdown-toggle .filter-option {
  position: relative;
  padding: 0; }

.natural-search__select .dropdown-menu, .quick-search__select .dropdown-menu {
  padding: 0; }
  .natural-search__select .dropdown-menu .active, .quick-search__select .dropdown-menu .active {
    background-color: #f5f5ed;
    color: #777; }
  .natural-search__select .dropdown-menu .disabled, .quick-search__select .dropdown-menu .disabled {
    display: none; }
  .natural-search__select .dropdown-menu .dropdown-item, .quick-search__select .dropdown-menu .dropdown-item {
    text-align: center; }

@media (max-width: 767.98px) {
  .natural-search__submit, .quick-search__submit {
    font-size: 0.95em; } }

.quick-search__select {
  max-width: 32%;
  width: 100%; }
  .quick-search__select .btn-light.dropdown-toggle {
    background: #FFFFFF;
    border: none; }
    .quick-search__select .btn-light.dropdown-toggle:disabled, .quick-search__select .btn-light.dropdown-toggle:hover {
      background: #FFFFFF;
      border: none; }
  .quick-search__select .dropdown-toggle:after, .quick-search__select .dropdown-toggle:before {
    display: none; }
  .quick-search__select .dropdown-toogle .filter-option, .quick-search__select .dropdown-toogle .filter-option-inner {
    padding-right: 0; }
  .quick-search__select .filter-option-inner-inner {
    text-align: center;
    color: #777; }

.natural-search__select .btn-light.dropdown-toggle {
  background: transparent;
  border: none; }
  .natural-search__select .btn-light.dropdown-toggle:disabled, .natural-search__select .btn-light.dropdown-toggle:hover {
    background: transparent;
    border: none; }

.natural-search__select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: auto;
  padding: 0 0.1rem; }

.natural-search__select .dropdown-toggle {
  padding: 0 5px;
  padding-right: 25px;
  margin-right: 5px;
  border: none;
  background: transparent;
  outline: none;
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  min-width: 70px; }
  .natural-search__select .dropdown-toggle:before {
    display: block;
    content: '';
    position: absolute;
    bottom: 3px;
    height: 1px;
    width: calc(100% - 5px);
    border-bottom: #777 solid 1px; }
  .natural-search__select .dropdown-toggle:after {
    content: "";
    border: none;
    margin: 0;
    vertical-align: unset;
    position: absolute;
    bottom: 5px;
    right: 0;
    width: unset;
    height: unset; }

.natural-search__select .filter-option-inner-inner {
  font-style: italic; }
  @media (min-width: 992px) {
    .natural-search__select .filter-option-inner-inner {
      font-size: 18px; } }

.natural-search__select .dropdown-menu .dropdown-item {
  padding-left: 5px;
  padding-right: 5px; }

@media (min-width: 1200px) {
  .natural-search__select button[data-id="region"] {
    min-width: 12.0625rem; } }

@media (min-width: 1200px) {
  .natural-search__select button[data-id="who"] {
    min-width: 14.3125rem; } }

@media (min-width: 1200px) {
  .natural-search__select button[data-id="type"] {
    min-width: 9.6875rem; } }

@media (min-width: 1200px) {
  .natural-search__select button[data-id="purpose"] {
    min-width: 10.375rem; } }

@media (min-width: 1200px) {
  .natural-search__select button[data-id="lifestyle"] {
    min-width: 19rem; } }

@media (min-width: 1200px) {
  .natural-search__select button[data-id="infrastructure"] {
    min-width: 21.25rem; } }

.stage-snippet:not(.stage-snippet--mainPage) .natural-search {
  display: none; }

.stage-snippet:not(.stage-snippet--mainPage) .quick-search {
  min-width: unset;
  width: 100%; }

.stage-snippet:not(.stage-snippet--mainPage) .divider-after:after {
  display: none; }

@media (max-width: 767.98px) {
  .natural-search {
    min-width: unset;
    width: 100%; }
  .quick-search {
    min-width: unset;
    width: 100%; }
  .divider-after:after {
    display: none; } }
